$(function(){
  $(':input[type=number]').on('mousewheel',function(e){ $(this).blur(); });
});

// The function actually applying the offset
function offsetAnchor() {
  if (location.hash.length !== 0) {
    window.scrollTo(window.scrollX, window.scrollY - 100);
  }
}

// Captures click events of all <a> elements with href starting with #
$(document).on('click', 'a[href^="#"]', function(event) {
  // Click events are captured before hashchanges. Timeout
  // causes offsetAnchor to be called after the page jump.
  window.setTimeout(function() {
    offsetAnchor();
  }, 0);
});

// Set the offset when entering page with hash present in the url
window.setTimeout(offsetAnchor, 0);

$(function() {
  $('.easy-pie-chart').easyPieChart();
});

$(document).ready(function(){
  $('.date-picker').flatpickr();

  $('#per_hour_rates').on('cocoon:after-insert', function(e) {
     $('.date-picker').flatpickr();
  });
});